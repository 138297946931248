import React, { useState, useEffect } from "react";
import { map, initial, last, round, max, min } from "lodash";
import { useQuery } from "react-query";
import { Doughnut, Line } from "react-chartjs-2";

const Start = () => {
  const [data, setData] = useState(null);

  const [filterValue, setFilterValue] = useState("letzte 8h");

  const timeaxis = map(data, (single) => {
    return `${single.date} (${single.api_weather_status}) (${
      single.rain_status == true ? "Regen" : "Trocken"
    })`;
  });

  const temp1 = map(data, (single) =>
    single.temp1 + single.hum1 != 0 ? single.temp1 : null
  );
  const temp2 = map(data, (single) =>
    single.temp2 + single.hum2 != 0 ? single.temp2 : null
  );
  const temp3 = map(data, (single) =>
    single.temp3 + single.hum3 != 0 ? single.temp3 : null
  );

  const api_temp = map(data, (single) => single.api_temp);
  const hum1 = map(data, (single) => (single.hum1 != 0 ? single.hum1 : null));
  const batteryMap = map(data, (single) =>
    single.vbat != 0 ? single.vbat : null
  );
  const hum2 = map(data, (single) => (single.hum2 != 0 ? single.hum2 : null));
  const hum3 = map(data, (single) => (single.hum3 != 0 ? single.hum3 : null));

  const currentData = last(data);
  const secondLastData = last(initial(data));

  const temperature = {
    labels: timeaxis,
    datasets: [
      {
        data: temp1,
        label: "Sonnenschutz",
        borderColor: "#3e95cd",
        fill: false,
      },
      {
        data: temp2,
        label: "Außen",
        borderColor: "#8e5ea2",
        fill: false,
      },
      {
        data: temp3,
        label: "Metallbox ",
        borderColor: "#FF0040",
        fill: false,
      },
      {
        data: api_temp,
        label: "API Temperatur ",
        borderColor: "#ff00ff",
        fill: false,
      },
    ],
  };

  const humidity = {
    labels: timeaxis,
    datasets: [
      {
        data: hum1,
        label: "Sonnenschutz",
        borderColor: "#3e95cd",
        fill: false,
      },
      {
        data: hum2,
        label: "Außen",
        borderColor: "#8e5ea2",
        fill: false,
      },
      {
        data: hum3,
        label: "Metallbox",
        borderColor: "#00FF00",
        fill: false,
      },
    ],
  };

  const battery = {
    labels: timeaxis,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    },
    datasets: [
      {
        data: batteryMap,
        label: "LiPo Volt",
        borderColor: "#3e95cd",
        fill: false,
      },
    ],
  };
  const prodUrl = "https://shop.firmen-api.com";
  const devUrl = "http://localhost:4777";

  const { isLoading, error, data: useQueryData, isFetching } = useQuery(
    "repoData",
    () =>
      fetch(prodUrl + "/todo/temps/last/" + 8).then(async (res) => {
        const myData = await res.json();

        setData(myData.serialize);
      })
  );

  if (isLoading) return "Loading...";

  // useEffect(() => {
  //   fetch(prodUrl + "/todo/temps/last/" + 8, {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json" },
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((resData) => {
  //       setData(resData.serialize);
  //       console.log("datata", resData.serialize);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const getFilteredData = (e) => {
    setFilterValue("letzte " + e.target.value + "h");
    fetch(prodUrl + "/todo/temps/last/" + e.target.value, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        setData(resData.serialize);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFilteredDatabyDay = (e) => {
    setFilterValue(e.target.value);
    console.log("vaue", e.target.value);
    fetch(prodUrl + "/todo/temps/day/" + e.target.value, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        setData(resData.serialize);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="m-2">
      <div>
        <h2>Aktuelle Werte</h2>

        {currentData ? (
          <>
            {currentData.date} (Update alle 10 Minuten)
            <hr />
            <p>
              Battery:{" "}
              <u>
                <b>{currentData.vbat} V</b>
              </u>
            </p>
            <p>
              Temperatur Sonnenschutz:{" "}
              <u>
                <b>
                  {currentData.temp1} °C ({" "}
                  {currentData.temp1 >= secondLastData.temp1 ? "+" : null}
                  {round(currentData.temp1 - secondLastData.temp1, 2)}°)
                </b>
              </u>
            </p>
            <p>
              Temperatur Außen:{" "}
              <u>
                <b>
                  {currentData.temp2} °C ({" "}
                  {currentData.temp2 >= secondLastData.temp2 ? "+" : null}
                  {round(currentData.temp2 - secondLastData.temp2, 2)}°)
                </b>
              </u>
            </p>
            <p>
              Temperatur Metall Box:{" "}
              <u>
                <b>
                  {currentData.temp3} °C ({" "}
                  {currentData.temp3 >= secondLastData.temp3 ? "+" : null}
                  {round(currentData.temp3 - secondLastData.temp3, 2)}°)
                </b>
              </u>
            </p>
            <p>
              Differenz Sonnenschutz zu Metallbox:{" "}
              <u>
                {currentData.temp3 >= currentData.temp3 ? "+" : null}
                <b>{round(currentData.temp1 - currentData.temp3, 2)}°C</b>
              </u>
            </p>
            <p>
              Luftfeuchtigkeit Sonnenschutz:{" "}
              <u>
                <b>
                  {currentData.hum1} % ({" "}
                  {currentData.hum1 >= secondLastData.hum1 ? "+" : null}
                  {round(currentData.hum1 - secondLastData.hum1, 2)}%)
                </b>
              </u>
            </p>
            <p>
              Luftfeuchtigkeit Außen:{" "}
              <u>
                <b>
                  {currentData.hum2} % ({" "}
                  {currentData.hum2 >= secondLastData.hum2 ? "+" : null}
                  {round(currentData.hum2 - secondLastData.hum2, 2)}%)
                </b>
              </u>
            </p>
            <p>
              Luftfeuchtigkeit Metall Box:{" "}
              <u>
                <b>
                  {currentData.hum3} % ({" "}
                  {currentData.hum3 >= secondLastData.hum3 ? "+" : null}
                  {round(currentData.hum3 - secondLastData.hum3, 2)}%)
                </b>
              </u>
            </p>
            <p>
              Temperatur API:{" "}
              <u>
                <b>
                  {currentData.api_temp} °C ({" "}
                  {currentData.api_temp >= secondLastData.api_temp ? "+" : null}
                  {round(currentData.api_temp - secondLastData.api_temp, 2)}°)
                </b>
              </u>
            </p>
            <p>
              Aktuelles Wetter laut API:{" "}
              <u>
                <b>{currentData.api_weather_status}</b>
              </u>
            </p>
          </>
        ) : (
          <b>Daten werden geladen...</b>
        )}
      </div>
      <hr />
      <h2>Temperatur in °C ({filterValue})</h2>
      <p>
        Sonnenschutz ⬆{max(temp1)}° ⬇{min(temp1)}° <br />
        Außen ⬆{max(temp2)}° ⬇{min(temp2)}°
        <br />
        Metallbox ⬆{max(temp3)}° ⬇{min(temp3)}°
        <br />
        Api Temperatur ⬆{max(api_temp)}° ⬇{min(api_temp)}°
      </p>
      {/*  <button onClick={() => getFilteredData(8)}>Letzte 8h</button>{" "}
      <button onClick={() => getFilteredData(4)}>Letzte 4h</button> */}
      <label for="timeframes">Daten Filtern:</label>
      <select onChange={(e) => getFilteredData(e)} id="cars">
        <option value="1">1 Stunde</option>
        <option value="2">2 Stunden</option>
        <option value="3">3 Stunden</option>
        <option value="4">4 Stunden</option>
        <option selected value="8">
          8 Stunden
        </option>
        <option value="10">10 Stunde</option>
        <option value="12">12 Stunden</option>
        <option value="16">16 Stunden</option>
        <option value="24">24 Stunden</option>
        <option value="48">48 Stunden</option>
        <option value="96">96 Stunden</option>
        <option value="120">120 Stunden</option>
      </select>{" "}
      oder <input onChange={(e) => getFilteredDatabyDay(e)} type="date" />{" "}
      <div>
        <Line data={temperature} />
      </div>
      <hr />
      <h2>Luftfeuchtigkeit in % ({filterValue})</h2>
      <p>
        Sonnenschutz ⬆{max(hum1)}% ⬇{min(hum1)}% <br />
        Außen ⬆{max(hum2)}% ⬇{min(hum2)}%
        <br />
        Metallbox ⬆{max(hum3)}% ⬇{min(hum3)}%
      </p>
      <div>
        <Line data={humidity} />
      </div>
      <h2>Batterie</h2>
      <p>
        Min/Max ⬆{max(batteryMap)}V ⬇{min(batteryMap)}V <br />
      </p>
      <div>
        <Line
          data={battery}
          options={{
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: false,
                    min: 3.9,
                    max: 4.3,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
};

export default Start;
