import React from "react";

import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import "./App.css";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Stats from "./components/stats.js";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        {" "}
        <Switch>
          <Route exact path="/" component={Stats}></Route>
          <Route component={Stats} />
        </Switch>
        <div className="container"></div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
